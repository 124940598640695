import React from "react";
import ReactDOM from "react-dom";
import {Rdi as RDIUI} from "rdi-ui";

const rdiContainerDiv = document.getElementById("document-viewer-container");
const isLender = rdiContainerDiv.getAttribute("data-is-lender-admin") === "";
const rdiEnabled = rdiContainerDiv.getAttribute("data-rdi-enabled") === "";
const taskSpecificCommentary = rdiContainerDiv.getAttribute("data-task-comments-enabled");

$.R.showDocumentViewer = (documentId, assigneeToken) => {
  ReactDOM.unmountComponentAtNode(rdiContainerDiv);
  // TODO: unmountComponentAtNode() will replace with .unmount() as we upgrade to React 18

  ReactDOM.render(
    <RDIUI documentToken={documentId} isLender={isLender} rdiEnabled={rdiEnabled}
           taskSpecificCommentary={taskSpecificCommentary} assigneeToken={assigneeToken} />
    , rdiContainerDiv
  );
}


